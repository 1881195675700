{
  "app_container": {
    "navbar_title": "Geesome Node",
    "server_address": "Node:",
    "main_menu": {
      "title": "Menu",
      "home": "Home",
      "profile": "Profile",
      "chat": "Chat",
      "file_explorer": "Files explorer",
      "boot_nodes": "Boot Nodes",
      "administration_groups": "Administrate groups",
      "followed_groups": "Followed groups"
    }
  },
  "main_page": {
    "file": "Upload file"
  },
  "group_page": {
    "new_post": "New post"
  },
  "choose_contents_ids_modal": {
    "title": "Add contents by explorer",
    "cancel": "Cancel",
    "ok": "Ok"
  },
  "choose_file_contents_ids_modal": {
    "title": "Choose file",
    "cancel": "Cancel",
    "ok": "Ok"
  },
  "new_user": {
    "limit_period": "Limit for each period"
  },
  "set_limit_modal": {
    "limit_period": "Limit for each period"
  },
  "user_profile": {
    
  },
  "period_input": {
    "unit_minutes": "Minutes",
    "unit_hours": "Hours",
    "unit_days": "Days",
    "period_value": "Value",
    "period_unit": "Unit"
  }
}
