{
    "name": "homestead",
    "comment": "Homestead hardfork with protocol and network changes",
    "eip": {
        "url": "https://eips.ethereum.org/EIPS/eip-606",
        "status": "Final"
    },
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {},
    "casper": {},
    "sharding": {}
}
