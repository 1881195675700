{
    "name": "muirGlacier",
    "comment": "HF to delay the difficulty bomb",
    "eip": {
        "url": "https://eips.ethereum.org/EIPS/eip-2384",
        "status": "Last Call"
    },
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {},
    "casper": {},
    "sharding": {}
}
