module.exports = `
<div id="main-page" style="height: 100%;">
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <welcome-to-geesome></welcome-to-geesome>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <created-for-admin-overview></created-for-admin-overview>
    </div>
  </div>
</div>
`;