{
    "name": "chainstart",
    "comment": "Start of the Ethereum main chain",
    "eip": {
        "url": "",
        "status": ""
    },
    "status": "",
    "gasConfig": {
        "minGasLimit": {
            "v": 5000,
            "d": "Minimum the gas limit may ever be"
        },
        "gasLimitBoundDivisor": {
            "v": 1024,
            "d": "The bound divisor of the gas limit, used in update calculations"
        }
    },
    "gasPrices": {
        "base": {
            "v": 2,
            "d": "Gas base cost, used e.g. for ChainID opcode (Istanbul)"
        },
        "tierStep": {
            "v": [0, 2, 3, 5, 8, 10, 20],
            "d": "Once per operation, for a selection of them"
        },
        "exp": {
            "v": 10,
            "d": "Once per EXP instuction"
        },
        "expByte": {
            "v": 10,
            "d": "Times ceil(log256(exponent)) for the EXP instruction"
        },
        "sha3": {
            "v": 30,
            "d": "Once per SHA3 operation"
        },
        "sha3Word": {
            "v": 6,
            "d": "Once per word of the SHA3 operation's data"
        },
        "sload": {
            "v": 50,
            "d": "Once per SLOAD operation"
        },
        "sstoreSet": {
            "v": 20000,
            "d": "Once per SSTORE operation if the zeroness changes from zero"
        },
        "sstoreReset": {
            "v": 5000,
            "d": "Once per SSTORE operation if the zeroness does not change from zero"
        },
        "sstoreRefund": {
            "v": 15000,
            "d": "Once per SSTORE operation if the zeroness changes to zero"
        },
        "jumpdest": {
            "v": 1,
            "d": "Refunded gas, once per SSTORE operation if the zeroness changes to zero"
        },
        "log": {
            "v": 375,
            "d": "Per LOG* operation"
        },
        "logData": {
            "v": 8,
            "d": "Per byte in a LOG* operation's data"
        },
        "logTopic": {
            "v": 375,
            "d": "Multiplied by the * of the LOG*, per LOG transaction. e.g. LOG0 incurs 0 * c_txLogTopicGas, LOG4 incurs 4 * c_txLogTopicGas"
        },
        "create": {
            "v": 32000,
            "d": "Once per CREATE operation & contract-creation transaction"
        },
        "call": {
            "v": 40,
            "d": "Once per CALL operation & message call transaction"
        },
        "callStipend": {
            "v": 2300,
            "d": "Free gas given at beginning of call"
        },
        "callValueTransfer": {
            "v": 9000,
            "d": "Paid for CALL when the value transfor is non-zero"
        },
        "callNewAccount": {
            "v": 25000,
            "d": "Paid for CALL when the destination address didn't exist prior"
        },
        "selfdestructRefund": {
            "v": 24000,
            "d": "Refunded following a selfdestruct operation"
        },
        "memory": {
            "v": 3,
            "d": "Times the address of the (highest referenced byte in memory + 1). NOTE: referencing happens on read, write and in instructions such as RETURN and CALL"
        },
        "quadCoeffDiv": {
            "v": 512,
            "d": "Divisor for the quadratic particle of the memory cost equation"
        },
        "createData": {
            "v": 200,
            "d": ""
        },
        "tx": {
            "v": 21000,
            "d": "Per transaction. NOTE: Not payable on data of calls between transactions"
        },
        "txCreation": {
            "v": 32000,
            "d": "The cost of creating a contract via tx"
        },
        "txDataZero": {
            "v": 4,
            "d": "Per byte of data attached to a transaction that equals zero. NOTE: Not payable on data of calls between transactions"
        },
        "txDataNonZero": {
            "v": 68,
            "d": "Per byte of data attached to a transaction that is not equal to zero. NOTE: Not payable on data of calls between transactions"
        },
        "copy": {
            "v": 3,
            "d": "Multiplied by the number of 32-byte words that are copied (round up) for any *COPY operation and added"
        },
        "ecRecover": {
            "v": 3000,
            "d": ""
        },
        "sha256": {
            "v": 60,
            "d": ""
        },
        "sha256Word": {
            "v": 12,
            "d": ""
        },
        "ripemd160": {
            "v": 600,
            "d": ""
        },
        "ripemd160Word": {
            "v": 120,
            "d": ""
        },
        "identity": {
            "v": 15,
            "d": ""
        },
        "identityWord": {
            "v": 3,
            "d": ""
        }
    },
    "vm": {
        "stackLimit": {
            "v": 1024,
            "d": "Maximum size of VM stack allowed"
        },
        "callCreateDepth": {
            "v": 1024,
            "d": "Maximum depth of call/create stack"
        },
        "maxExtraDataSize": {
            "v": 32,
            "d": "Maximum size extra data may be after Genesis"
        }
    },
    "pow": {
        "minimumDifficulty": {
            "v": 131072,
            "d": "The minimum that the difficulty may ever be"
        },
        "difficultyBoundDivisor": {
            "v": 2048,
            "d": "The bound divisor of the difficulty, used in the update calculations"
        },
        "durationLimit": {
            "v": 13,
            "d": "The decision boundary on the blocktime duration used to determine whether difficulty should go up or not"
        },
        "epochDuration": {
            "v": 30000,
            "d": "Duration between proof-of-work epochs"
        },
        "timebombPeriod": {
            "v": 100000,
            "d": "Exponential difficulty timebomb period"
        },
        "minerReward": {
            "v": "5000000000000000000",
            "d": "the amount a miner get rewarded for mining a block"
        }
    },
    "casper": {},
    "sharding": {}
}
